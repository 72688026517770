import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "firstName", 'lastName'];

  prefillFields(event) {
    fetch(`/dashboard/users/${event.detail.choice.value}.json`)
      .then(response => response.json())
      .then(item => {
        this.firstNameTarget.value = item.first_name;
        this.lastNameTarget.value = item.last_name;
        this.lastNameTarget.dispatchEvent(new Event("keyup"));
      });
  }
}
