import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle", "label", "publicToggle"];

  connect() {
    const checked = this.toggleTarget.checked;

    this.toggleInputs(!checked);
  }

  toggle(event) {
    const checked = event.target.checked;

    this.toggleInputs(!checked);
  }

  toggleInputs(show) {
    this.labelTarget.disabled = show;
    this.publicToggleTarget.disabled = show;
  }
}
