import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["lectures", "posters"];

  connect() {
    this.lecturesTarget.classList.add('is-hidden');
    this.postersTarget.classList.add('is-hidden');
  }

  chooseType(event) {
    switch (event.target.value){
    case 'lectures':
      this.lecturesTarget.classList.remove('is-hidden');
      this.postersTarget.classList.add('is-hidden');
      break;
    case 'posters':
      this.lecturesTarget.classList.add('is-hidden');
      this.postersTarget.classList.remove('is-hidden');
      break;
    }
  }
}
