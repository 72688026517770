import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["file", "removeInput", "currentlyUploaded"];

  connect() {
    this.fileInput = this.fileTarget;
    this.submitButton = this.fileInput.closest('form').querySelector('input[type="submit"]');
    this.oldSubmitValue = this.submitButton.value;
  }

  validate() {
    const maxFileSize = this.fileInput.dataset.maxFileSize;
    const humanizedFileSize = this.humanizeFileSize(maxFileSize);

    this.submitButton.value = 'Checking file...';
    this.submitButton.disabled = true;

    if (this.fileInput.files[0].size > maxFileSize) {
      alert(`Max file size is ${humanizedFileSize}`);
      this.submitButton.value = 'File invalid';
    } else {
      this.submitButton.disabled = false;
      this.submitButton.value = this.oldSubmitValue;
    }
  }

  humanizeFileSize(size) {
    let unit = 'b';

    if (size >= 1024) { // kb
      size = size / 1024;
      unit = 'kb';
    } else if (size >= 1048576) { // mb
      size = size / 1024 / 1024;
      unit = 'mb';
    } else if (size >= 1073741824) { // gb
      size = size / 1024 / 1024 / 1024;
      unit = 'gb';
    }

    return `${size} ${unit}`;
  }

  hideDocumentLink() {
    const currentlyUploaded = this.currentlyUploadedTarget;

    currentlyUploaded.classList.add('hidden');
  }

  removeFile() {
    this.hideDocumentLink();
    this.removeInputTarget.value = true;
  }
}
