import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["main"];
  static values = { class: String };

  connect() {
    this.klass = this.classValue || 'is-active';
  }

  toggle(event) {
    event.preventDefault();
    this.mainTarget.classList.toggle(this.klass);
  }
}
