import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slug", "content"];

  // we can't use disabled here since it swallows click events
  enableSlugOverride(){
    if (this.slugTarget.readOnly){
      this.slugTarget.removeAttribute('readonly');
      this.slugTarget.classList.toggle('readonly');
    }
  }

  update(){
    if (!this.slugTarget.readOnly){
      const csrfMetaElement = document.querySelector(`meta[name=csrf-token]`);
      const csrfToken = csrfMetaElement && csrfMetaElement.content;
      fetch('/dashboard/slugs', {
        method: 'POST',
        headers: {
          "X-CSRF-Token": csrfToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "input": this.contentTargets[0].value })
      }).then(response => response.json())
        .then(response => this.slugTarget.value = response['slug']);
    }
  }
}
