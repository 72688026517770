import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import ProgressBar from "@uppy/progress-bar";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import DragDrop from "@uppy/drag-drop";
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = [
    "file",
    "drop",
    "container",
    "hiddenInput",
    "removeInput",
    "removeButton",
    "progressBar",
    "preview",
    "canvas"
  ];

  static values = {
    attributeName: String,
    aspectRatio: Number,
    maxFileSize: Number,
    viewMode: String,
    previewUrl: String
  };

  connect() {
    // getting data
    this.setupUppy();

    // setting up container and preview
    const height = 275;
    this.containerTarget.style.height = `${height}px`;
    this.containerTarget.style.width = `${height * this.aspectRatioValue}px`;
    if (this.previewUrlValue !== "") {
      this.dropTarget.classList.add('has-image');
      this.dropTarget.style.backgroundImage = `url(${this.previewUrlValue})`;
      this.removeButtonTarget.classList.remove('is-hidden');
    }
  }

  // Events

  removeImage(event) {
    event.preventDefault();
    this.dropTarget.style.backgroundImage = 'none';
    this.dropTarget.classList.remove('has-image');
    this.dropTarget.classList.remove('is-hidden');
    this.removeInputTarget.value = true;
    this.removeButtonTarget.classList.add('is-hidden');
    const error = this.element.querySelector('.help.is-danger');
    if (error) {
      error.innerHTML = "";
    }
    if (this.cropper) {
      this.cropper.destroy();
      this.canvasTarget.src = "";
    }
  }

  // private

  setupUppy() {
    const uppy = Uppy({
      autoProceed: true,
      debug: true,
      restrictions: {
        maxFileSize: this.maxFileSizeValue,
        allowedFileTypes: ['image/jpg', 'image/jpeg', 'image/png']
      }
    });

    uppy.use(ProgressBar, {
      id: this.attributeNameValue,
      target: this.progressBarTarget,
      fixed: true
    });

    uppy.use(AwsS3Multipart, {
      limit: 1,
      timeout: 900 * 1000,
      companionUrl: '/'
    });

    uppy.use(DragDrop, {
      target: this.dropTarget
    });

    uppy.on('upload-success', this.uploadSuccess.bind(this));

    this.uploadClient = uppy;
  }

  uploadSuccess(file, response) {
    const uploadedFileData = JSON.stringify({
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // eslint-disable-line no-useless-escape
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type // eslint-disable-line camelcase
      }
    });

    this.hiddenInputTarget.value = uploadedFileData;
    this.removeButtonTarget.classList.remove('is-hidden');
    this.application.getControllerForElementAndIdentifier(this.element, "modal").show();
    this.removeInputTarget.value = false;

    if (typeof this.canvasTarget.cropper === 'object') {
      this.canvasTarget.cropper.destroy();
    }

    this.setupCropbox(response.uploadURL);
  }

  setupCropbox(url) {
    const viewMode = this.viewModeValue === "normal" ? 0 : 1;

    this.canvasTarget.src = url;

    this.dropTarget.classList.add('is-hidden');

    this.cropper = new Cropper(this.canvasTarget, {
      viewMode: viewMode,
      restore: false,
      movable: true,
      aspectRatio: this.aspectRatioValue,
      cropBoxResizable: true,
      checkCrossOrigin: false,
      preview: this.previewTarget,
      crop: event => this.onCrop(event.detail, this.viewModeValue)
    });
  }

  onCrop(detail, viewMode) {
    const fileData = JSON.parse(this.hiddenInputTarget.value);
    // reduce the crop to be within image
    const x2 = Math.min(detail.width + detail.x, this.cropper.getImageData().naturalWidth);
    const y2 = Math.min(detail.height + detail.y, this.cropper.getImageData().naturalHeight);
    detail.x = Math.max(detail.x, 0);
    detail.y = Math.max(detail.y, 0);
    detail.width = x2 - detail.x;
    detail.height = y2 - detail.y;
    detail.aspect_ratio = this.aspectRatioValue; // eslint-disable-line camelcase
    detail.view_mode = viewMode; // eslint-disable-line camelcase

    fileData['metadata']['crop'] = detail;
    this.hiddenInputTarget.value = JSON.stringify(fileData);
  }
}
