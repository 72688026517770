import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "container"];

  connect() {
    this.hideContainers();
    this.selectTargets.forEach(select => {
      select.addEventListener("change", this.showContainer.bind(this));
      this.findAndShowContainer(select);
    });
  }

  showContainer(event) {
    this.hideContainers();
    this.findAndShowContainer(event.target);
  }

  // private

  findAndShowContainer(select) {
    let value = select.type === "checkbox" ? select.checked : select.value;
    value = value.replace(/_/g, '-');
    const containerName = select.getAttribute(`data-select-${value}`);
    if (containerName === null) {
      return;
    }

    const container = this.containerTargets.find(target => target.dataset.container === containerName);
    container.classList.remove("is-hidden");
    container.querySelectorAll('input, select').forEach(input => {
      input.disabled = false;
    });
  }

  hideContainers() {
    this.containerTargets.forEach(container => {
      container.classList.add("is-hidden");
      container.querySelectorAll('input, select').forEach(input => {
        input.disabled = true;
      });
    });
  }
}
