import { Controller } from "stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["form", "frame", "loader", "content"];

  initialize() {
    this.search = debounce(this.search, 500);
  }

  search() {
    this.loaderTarget.classList.remove('is-hidden');
    this.contentTarget.classList.add('is-hidden');
    const searchParams = new URLSearchParams(new FormData(this.formTarget));
    const url = `${this.formTarget.action}?${searchParams}`;
    this.frameTarget.src = url;
    history.pushState({ turbo_frame_history: true }, '', url); // eslint-disable-line camelcase
  }
}
