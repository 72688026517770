import { Controller } from "stimulus";
import EasyMDE from "easymde";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    const textArea = this.fieldTarget;
    this.editor = this.buildEditor(textArea);
    this.buildDatePicker();
  }

  buildEditor(element) {
    return new EasyMDE({
      element: element,
      forceSync: true,
      toolbar: [
        {
          name: "paragraph",
          title: "Paragraph",
          className: 'fa fa-header fa-heading',
          children: [
            "heading-1",
            "heading-2",
            "heading-3",
            "heading-smaller",
            "heading-bigger"
          ]
        },
        "|", "bold", "italic", "quote",
        "|", "unordered-list", "ordered-list",
        "|", "link",
        {
          name: "datepicker",
          className: 'fa fa-calendar',
          title: "Datepicker",
          action: function() {}
        },
        {
          name: "video",
          className: 'fab fa-youtube',
          title: "Video",
          action: function(editor) {
            const cm = editor.codemirror;
            const startPoint = cm.getCursor('start');
            cm.replaceRange(
              `<iframe width="560" height="315" src="https://www.youtube.com/embed/video-id?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, // eslint-disable-line max-len
              startPoint
            );
            const curStart = { line: startPoint.line, ch: startPoint.ch + 68 };
            const curEnd = { line: startPoint.line, ch: startPoint.ch + 68 + 8 };
            cm.doc.setSelection(curStart, curEnd);
            cm.focus();
          }
        },
        {
          name: "button",
          className: 'fas fa-square',
          title: "Button",
          action: function(editor) {
            const cm = editor.codemirror;
            const startPoint = cm.getCursor('start');
            cm.replaceRange(
              `<button name="button" onclick="window.open('https://www.google.com','blank')">Click me</button>`,
              startPoint
            );
            const curStart = { line: startPoint.line, ch: startPoint.ch + 44 };
            const curEnd = { line: startPoint.line, ch: startPoint.ch + 44 + 22 };
            cm.doc.setSelection(curStart, curEnd);
            cm.focus();
          }
        }
      ],
      uploadImage: true,
      imageUploadFunction: this.uploadImage,
      autoDownloadFontAwesome: false
    });
  }

  uploadImage(file, onSuccess, onError) {
    const csrfMetaElement = document.querySelector(`meta[name=csrf-token]`);
    const csrfToken = csrfMetaElement && csrfMetaElement.content;
    const imageUploadUrl = "/event_dashboard/image_uploads";
    const formData = new FormData();

    formData.append('image', file);
    formData.append('authenticity_token', csrfToken);

    fetch(imageUploadUrl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData
    })
      .then((response) => {
        if (response.ok || response.redirected) {
          return response.json();
        } else {
          throw response;
        }
      }).then((body) => {
        onSuccess(body.data.filePath);
      }).catch(() => {
        onError('Upload failed. Please check that your file is supported.');
      });
  }

  buildDatePicker() {
    const editor = this.editor;
    flatpickr(
      editor.toolbarElements.datepicker,
      {
        enableTime: true,
        time_24hr: true, // eslint-disable-line camelcase
        dateFormat: "U",
        onChange: function(selectedDates, dateStr, instance) {
          instance.selectedDateStr = selectedDates[0].toISOString();
        },
        onClose: function(selectedDates, dateStr, instance) {
          if (instance.selectedDateStr == null) {
            return;
          }
          const cm = editor.codemirror;
          const startPoint = cm.getCursor('start');
          cm.replaceRange(
            `<timestamp>${instance.selectedDateStr}</timestamp>`,
            startPoint
          );
          instance.selectedDateStr = null;
        }
      }
    );
  }

  getMetaContent(name) {
    const element = document.querySelector(`meta[name="${name}"]`);
    return element && element.content;
  }
}
