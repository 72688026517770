import { Controller } from "stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["field"];
  static values = {
    shouldSort: Boolean,
    searchDisabled: Boolean,
    noRemoveItemButton: Boolean
  };

  connect() {
    const fieldInput = this.fieldTarget;

    new Choices(fieldInput, {
      itemSelectText: 'Select',
      removeItemButton: !this.noRemoveItemButtonValue, // TODO #3135
      shouldSort: this.shouldSortValue || true, // FIXME (false || true) == true
      searchEnabled: !this.searchDisabledValue, // TODO #3135
    });
  }
}
