import "stylesheets/dashboard.scss";
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

require.context('../icons', true);

// stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Clipboard from "stimulus-clipboard";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);

Rails.start();
application.load(definitionsFromContext(context));
application.register('clipboard', Clipboard);

