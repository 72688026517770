import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["video", "chat"];

  update(event){
    const vimeoRegex = RegExp(/https:\/\/vimeo.com\/(.*)\/(.*)/gm);
    const result = vimeoRegex.exec(event.target.value) || ["", "", ""];

    this.videoTarget.value = result[1];
    this.chatTarget.value = result[2];
  }
}
