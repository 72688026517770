import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hidable", "toggle"];
  static values = { storageKey: String };

  connect() {
    this.togglesCount = this.toggleTargets.length;
    this.config = this.setConfig();
    // All columns are show at the start, we need to toggle only those not selected
    this.hideNotChecked();
  }

  toggle(event) {
    const index = this.toggleTargets.indexOf(event.target);
    this.toggleColumn(index);
    this.config[index] = !this.config[index];
    localStorage.setItem(
      this.storageKeyValue,
      JSON.stringify(this.config)
    );
  }

  // private

  toggleColumn(index) {
    for (
      let i = index;
      i < this.hidableTargets.length;
      i = i + this.togglesCount
    ) {
      this.hidableTargets[i].classList.toggle("is-hidden");
    }
  }

  setConfig() {
    const config = JSON.parse(
      localStorage.getItem(this.storageKeyValue) || "{}"
    );

    if (Object.keys(config).length !== 0) {
      return config;
    }

    // prefill config if local storage is empty
    this.toggleTargets.forEach((target, index) => {
      config[index] = target.hasAttribute("checked");
    });

    localStorage.setItem(this.storageKeyValue, JSON.stringify(config));
    return config;
  }

  hideNotChecked() {
    this.toggleTargets.forEach((target, index) => {
      if (this.config[index]) {
        target.setAttribute("checked", "checked");
      } else {
        this.toggleColumn(index);
        target.removeAttribute("checked");
      }
    });
  }
}
