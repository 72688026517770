import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "showIndicator", "hideIndicator"];
  static values = { storageKey: String, initial: Boolean };

  connect() {
    // the initial-value data attribute must be a string because this.hasInitialValue returns false
    // if the attribute is set to a boolean false.
    if (this.hasInitialValue) {
      this.saveToggleState(this.initialValue.toString());
    }

    // It is shown by default, we want to hide on first load
    if (this.getToggleState() === "false") {
      this.containerTarget.classList.add("is-hidden");
      this.hideIndicatorTarget.classList.add("is-hidden");
    } else {
      this.showIndicatorTarget.classList.add("is-hidden");
    }
  }

  toggle(e) {
    e.preventDefault();
    if (this.getToggleState() === "true") {
      this.containerTarget.classList.add("is-hidden");
      this.showIndicatorTarget.classList.remove("is-hidden");
      this.hideIndicatorTarget.classList.add("is-hidden");
      this.saveToggleState("false");
    } else {
      this.containerTarget.classList.remove("is-hidden");
      this.showIndicatorTarget.classList.add("is-hidden");
      this.hideIndicatorTarget.classList.remove("is-hidden");
      this.saveToggleState("true");
    }
  }

  saveToggleState(state) {
    if (this.storageKeyValue === null) {
      return;
    }

    localStorage.setItem(this.storageKeyValue, state);
  }

  getToggleState() {
    if (this.storageKeyValue === null) {
      return "false";
    }

    return localStorage.getItem(this.storageKeyValue) || "false";
  }
}
