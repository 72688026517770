import { Controller } from "stimulus";

const acceptedTypes = ['external_aaa', 'external_aapt'];

export default class extends Controller {
  static targets = ['url', 'content'];

  update(){
    const externalType = this.contentTargets[0].value;

    if (acceptedTypes.includes(externalType)){
      const eventId = this.contentTargets[0].getAttribute('data-event-id');
      const csrfMetaElement = document.querySelector(`meta[name=csrf-token]`);
      const csrfToken = csrfMetaElement && csrfMetaElement.content;

      fetch('/dashboard/events/external_login_url', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'event_id': eventId, 'external_type': externalType })
      }).then(response => response.json())
        .then(response => this.urlTarget.value = response['external_login_url']);
    } else {
      this.urlTarget.value = '';
    }
  }
}
