import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["field"];
  static values = { enableTime: Boolean };

  connect() {
    const inputFields = this.fieldTargets;
    const self = this;

    inputFields.forEach(function(inputField) {
      self.build(inputField);
    });
  }

  build(element) {
    flatpickr(element, {
      enableTime: this.enableTimeValue,
      minDate: new Date(2019, 1, 1),
      maxDate: new Date(2030, 12, 30)
    });
  }
}
