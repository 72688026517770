import { Controller } from "stimulus";

export default class extends Controller{
  static targets = [
    "fields"
  ];

  addRecord(event) {
    const time = new Date().getTime();
    const regexp = new RegExp(event.target.getAttribute("data-fields-id"), "g");
    const div = document.createElement("div");
    div.innerHTML = event.target.getAttribute("data-fields-blueprint").replace(regexp, time);
    this.fieldsTarget.append(div.firstChild);
    event.preventDefault();
  }
}
