import { Controller } from "stimulus";

export default class extends Controller{
  static targets = [
    "item",
    "itemDestroyInput",
    "itemInput"
  ];

  removeItem(event) {
    if (this.hasItemDestroyInputTarget) {
      this.itemDestroyInputTarget.value = 1;
    }
    this.itemTarget.classList.add("is-hidden");
    this.itemInputTargets.forEach(x => {
      x.setAttribute('disabled', "");
    });
    event.preventDefault();
  }

  destroyItem() {
    this.itemTarget.remove();
  }
}
