import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["wrapper"];

  connect() {
    const wrapper = this.wrapperTarget;

    Sortable.create(wrapper, {
      animation: 150,
      onEnd: function(event) {
        const targetElement = event.item;
        const forerunner = targetElement.previousElementSibling;
        const forerunnerPosition = forerunner == null ? 0 : parseFloat(forerunner.dataset["position"]);
        const follower = targetElement.nextElementSibling;
        const followerPosition = follower == null ? forerunnerPosition + 10 : parseFloat(follower.dataset["position"]);

        const updatedTargetPosition = (forerunnerPosition + followerPosition) / 2;

        const csrfToken = document.querySelector("[name='csrf-token']").content;
        const updateUrl = targetElement.dataset["updateUrl"];
        const resource = targetElement.dataset["resource"];

        const data = {};
        data[resource] = { position: updatedTargetPosition };

        fetch(updateUrl, {
          method: "PATCH",
          headers: {
            "X-CSRF-Token": csrfToken,
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
          .then((response) => {
            if (response.ok || response.redirected) {
              return response;
            } else {
              throw response;
            }
          }).then(() => {
            targetElement.dataset["position"] = updatedTargetPosition;
          }).catch((error) => {
            console.error("Sorting error!", error);
          });
      }
    });
  }
}
