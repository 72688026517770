import { Controller } from "stimulus";
import Choices from "choices.js";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["field"];
  static values = {
    url: String,
    queryName: String,
    addItems: Boolean,
    additionalQueryParams: String
  };

  initialize() {
    this.search = debounce(this.search, 500);
  }

  connect(){
    const fieldInput = this.fieldTarget;

    this.choices = new Choices(fieldInput, {
      removeItemButton: true,
      searchPlaceholderValue: 'Type to search',
      noChoicesText: this.addItemsValue ? 'Press Enter to add item' : 'No choices to choose from',
      shouldSort: false,
      searchChoices: false,
      position: 'bottom'
    });
    if (this.addItemsValue) {
      this.choices.input.element.addEventListener('keyup', (event) => {
        if (event.keyCode === 13 && event.target.value) {
          this.addItem(event.target.value);
          event.target.value = '';
        }
      });
    }
  }

  search(event) {
    const query = event.detail.value;
    const url = new URL(this.urlValue, window.location.href);

    if (query !== undefined) {
      url.searchParams.append(`q[${this.queryNameValue}]`, query);
    }

    if (this.additionalQueryParamsValue != null) {
      Object.entries(JSON.parse(this.additionalQueryParamsValue)).forEach(([key, value]) => {
        if (value !== null) {
          if (Array.isArray(value)) {
            value.forEach(val => url.searchParams.append(`q[${key}][]`, val));
          } else {
            url.searchParams.append(`q[${key}]`, value);
          }
        }
      });
    }

    fetch(url)
      .then(response => response.json())
      .then(items => {
        this.choices.setChoices(items, 'id', 'label', true);
      });
  }

  addItem(value) {
    this.choices.setChoices([{ value: value, label: value, selected: true }], 'value', 'label', false);
  }
}
