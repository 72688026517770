import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["show", "select"];

  connect() {
    this.selectTarget.classList.add("is-hidden");
  }

  change(event) {
    event.preventDefault();
    this.showTarget.classList.add("is-hidden");
    this.selectTarget.classList.remove("is-hidden");
  }
}
