import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["main"];

  show(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.mainTarget.classList.add("is-active");
  }

  hide(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.type === "keyup" && event.code !== "Escape") {
      return;
    }
    this.mainTarget.classList.remove("is-active");
  }
}
