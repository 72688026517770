import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "rest",
    "userFound",
    "field",
    "continueButton",
    "submitButton"
  ];
  static values = { url: String };

  // events

  findUser(event) {
    if (event.code && event.code !== "Enter") {
      return;
    }
    event.preventDefault();
    const query = this.inputTarget.value.trim();
    const url = new URL(this.urlValue, window.location.href);

    if (query === "") {
      this.reset();
    } else if (query !== undefined) {
      url.searchParams.append(`q[email_eq]`, query.toLowerCase());

      fetch(url)
        .then((response) => response.json())
        .then((items) => {
          this.showFullForm(items[0]);
        });
    }

  }

  reset(event) {
    if (event) {
      event.preventDefault();
    }
    this.restTargets.forEach((field) => field.classList.add("is-hidden"));
    this.userFoundTarget.classList.add("is-hidden");
    this.continueButtonTarget.classList.remove("is-hidden");
    this.submitButtonTarget.classList.add("is-hidden");
  }

  // private

  showFullForm(item) {
    if (item === undefined) {
      this.fieldTargets.forEach((field) => (field.disabled = false));
      this.restTargets.forEach((field) => field.classList.remove("is-hidden"));
      this.userFoundTarget.classList.add("is-hidden");
      this.continueButtonTarget.classList.add("is-hidden");
    } else {
      this.fieldTargets.forEach((field) => (field.disabled = true));
      this.restTargets.forEach((field) => field.classList.add("is-hidden"));
      this.userFoundTarget.classList.remove("is-hidden");
      this.continueButtonTarget.classList.add("is-hidden");
    }
    this.submitButtonTarget.classList.remove("is-hidden");
  }
}
