import { Controller } from "stimulus";

const AColorPicker = require('a-color-picker');

export default class extends Controller {
  static targets = ["picker", "input"];

  connect() {
    this.initPicker();
  }

  initPicker() {
    this.picker = AColorPicker.createPicker(this.pickerTarget, {
      showHEX: false,
      showHSL: false,
      showRGB: false,
      showAlpha: true,
      color: this.inputTarget.value,
      palette: [
        '#19A68F',
        'rgba(25, 166, 143, 0.1)',
        '#FFFFFF',
        '#5B1CF4',
        'rgba(91, 28, 244, 0.1)',
        '#EF22FF',
        'rgba(239, 34, 255, 0.1)',
        '#0788FF',
        'rgba(7, 136, 255, 0.1)',
        '#EF5085',
        'rgba(239, 80, 133, 0.1)',
      ]
    }).on('change', (picker) => {
      this.inputTarget.value = AColorPicker.parseColor(picker.color, "hexcss4");
    });
  }

  update(){
    if (/^#[0-9A-F]{6,8}$/i.test(this.inputTarget.value)){
      this.picker.color = this.inputTarget.value;
    }
  }
}
