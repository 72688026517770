import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["main", "dependent"];

  connect(){
    const self = this;

    this.mainTarget.addEventListener('choice', event => {
      this.dependentTargets.forEach(target => {
        const rawParams = target.getAttribute('data-fetch-selection-additional-query-params-value');
        const params = rawParams !== null ?  JSON.parse(rawParams) : {};
        params['event_id_eq'] = event.detail.choice.value;
        target.setAttribute('data-fetch-selection-additional-query-params-value', JSON.stringify(params));
        const controller = self.application.getControllerForElementAndIdentifier(target, 'fetch-selection');
        controller.choices.clearStore();
        controller.choices.clearInput();
      });
    });
  }
}
