import { Controller } from "stimulus";
import videojs from "video.js";

export default class extends Controller {
  static targets = ["video"];

  connect(){
    const videoElement = this.videoTarget;

    const player = videojs(videoElement);

    player.width("400");
    player.height("268");
  }
}
