import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "submit"];

  updateFilename(event) {
    const fileName = event.target.value.split('\\').pop();
    this.inputTarget.innerHTML = fileName;
    this.submitTarget.removeAttribute('disabled');
  }
}
