import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import ProgressBar from "@uppy/progress-bar";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["file", "hiddenInput", "removeInput", "progressBar", "oldImagePreview", "canvas"];

  connect() {
    const fileInput = this.fileTarget;
    const hiddenInput = this.hiddenInputTarget;
    const maxFileSize = parseInt(fileInput.dataset.maxFileSize);
    const canvas = this.canvasTarget;
    const self = this;

    const uppy = Uppy({
      autoProceed: true,
      debug: true,
      restrictions: {
        maxFileSize: maxFileSize,
        allowedFileTypes: ['image/*']
      }
    });

    uppy.use(ProgressBar, {
      target: this.progressBarTarget
    });

    uppy.use(AwsS3Multipart, {
      limit: 1,
      timeout: 900 * 1000,
      companionUrl: '/'
    });

    uppy.on('upload-success', function(file, response) {
      const uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // eslint-disable-line no-useless-escape
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type // eslint-disable-line camelcase
        }
      });

      hiddenInput.value = uploadedFileData;

      self.hideOldImagePreview();

      if (typeof canvas.cropper === 'object') {
        canvas.cropper.destroy();
      }

      self.cropbox(canvas, response.uploadURL, {
        onCrop(detail, aspectRatio, viewMode) {
          const fileData = JSON.parse(hiddenInput.value);
          // reduce the crop to be within image
          const x2 = Math.min(detail.width + detail.x, self.cropper.getImageData().naturalWidth);
          const y2 = Math.min(detail.height + detail.y, self.cropper.getImageData().naturalHeight);
          detail.x = Math.max(detail.x, 0);
          detail.y = Math.max(detail.y, 0);
          detail.width = x2 - detail.x;
          detail.height = y2 - detail.y;
          detail.aspect_ratio = aspectRatio; // eslint-disable-line camelcase
          detail.view_mode = viewMode; // eslint-disable-line camelcase

          fileData['metadata']['crop'] = detail;
          hiddenInput.value = JSON.stringify(fileData);
        }
      });
    });

    this.uploadClient = uppy;
    this.fileInput = fileInput;
  }

  upload() {
    const files = Array.from(event.target.files);
    const file = files[files.length - 1];

    try {
      this.uploadClient.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file
      });

      this.uploadClient.upload();
    } catch (error) {
      alert(error);
    }
  }

  cropbox(canvas, url, { onCrop }) {
    const aspectRatioData = this.fileInput.dataset["aspectRatio"] || NaN;
    const aspectRatioHash = Number.isNaN(aspectRatioData) ? NaN : JSON.parse(aspectRatioData);
    const aspectRatio = Number.isNaN(aspectRatioData) ? NaN : (aspectRatioHash.width / aspectRatioHash.height);
    const dragMode = this.fileInput.dataset["onlyMove"] === undefined ? 'crop' : 'move';
    const viewMode = this.fileInput.dataset["viewMode"] === "normal" ? 0 : 1;

    canvas.src = url;

    this.cropper = new Cropper(canvas, {
      viewMode: viewMode,
      restore: false,
      movable: false,
      aspectRatio: aspectRatio,
      cropBoxResizable: true,
      dragMode: dragMode,
      checkCrossOrigin: false,
      crop: event => onCrop(event.detail, aspectRatio, this.fileInput.dataset["viewMode"])
    });
  }

  hideOldImagePreview() {
    const oldImagePreview = this.oldImagePreviewTarget;

    if (typeof (oldImagePreview) != 'undefined' && oldImagePreview != null) {
      oldImagePreview.classList.add('hidden');
    }
  }

  removeImage() {
    this.hideOldImagePreview();
    this.removeInputTarget.value = true;
  }
}
