import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3Multipart from "@uppy/aws-s3-multipart";

export default class extends Controller {
  static targets = ["file"];

  connect(){
    const fileInput = this.fileTarget;
    const maxFileSize = parseInt(fileInput.dataset.maxFileSize);
    fileInput.style.display = 'none';

    const uppy = Uppy({
      autoProceed: false,
      debug: false,
      restrictions: {
        maxFileSize: maxFileSize,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['video/*']
      }
    });

    uppy.use(Dashboard, {
      inline: true,
      target: '.js-drag-drop-video',
      showProgressDetails: true,
      height: 250,
      width: 400,
      browserBackButtonClose: true
    });

    uppy.use(AwsS3Multipart, {
      limit: 1,
      timeout: 900 * 1000,
      companionUrl: '/'
    });

    uppy.on('upload-success', function(file, response) {
      const uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // eslint-disable-line no-useless-escape
        storage: 'cache',
        metadata: {
          size:      file.size,
          filename:  file.name,
          mime_type: file.type // eslint-disable-line camelcase
        }
      });

      const videoForm = fileInput.closest('form');

      videoForm.querySelector('.js-video-upload-hidden').value = uploadedFileData;
      videoForm.submit();
    });
  }
}
